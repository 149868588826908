import React from "react";
import styled from 'styled-components';

import imgLogoMenu from "#assets/logo40.png";

import imgLogoPageAccueil from "#assets/logo100.png";
import imgLogoPage from "#assets/logo75.png";

import useConfig from "#utils/config";


//--------------------------------------------------------------
const LogoImg = styled.img`    
    padding : 0.1em 0.5em;
    text-align : center;
`;


// pour pages

const LogoPageCt= styled.div`    
    text-align : center;
    margin-top : 1em;
    margin-bottom : 1.5em;
`;
const LogoPageImg = styled.img`    
    padding : 0.1em 0.5em;
    text-align : center;
`;

const LogoPageBaseLine = styled.div`
    padding : 0.1em 0.5em;
    text-align : center;
    font-weight : bold;
    font-size : .75em;
    text-transform: uppercase;
`;

//--------------------------------------------------------------

export const LogoPageAccueil = (withBaseLine) => {
    const {siteName, baseLineLogo} = useConfig();    
    const  text = withBaseLine ? baseLineLogo : '';
    return (
        <LogoPageCt>
            <LogoPageImg src={imgLogoPageAccueil} alt={siteName} />
            <LogoPageBaseLine>{text}</LogoPageBaseLine>
        </LogoPageCt>
    )
}

export const LogoPage = (withBaseLine) => {
    const {siteName, baseLineLogo} = useConfig();    
    const  text = withBaseLine ? baseLineLogo : '';
    return (
        <LogoPageCt>
            <LogoPageImg src={imgLogoPage} alt={siteName} />
            <LogoPageBaseLine>{text}</LogoPageBaseLine>
        </LogoPageCt>
    )
}

export const Logo = () => {
    const {siteName} = useConfig();    
    return (
        <LogoImg src={imgLogoMenu} alt={siteName} />
    )
}
