import { useAuth } from '../utils/auth';

export function useApiMyAccount() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/my_account'
  const resourceNew = auth.apiBaseUrl + '/my_new_account'

  const getData = async function () {
    return fetch(resource, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"

    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }            
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      

    })
  };


  const updateData = async function (data) {
    return fetch(resource + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })

  };

  
  const create = async function (data) {
    return fetch(resourceNew, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "POST",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      

    })

  };

  const updateFlagConfirmed = async function (data) {
    return fetch(resourceNew + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  }  

  const sendNewToken = async function (data) {    
    return fetch(resourceNew + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",      
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  }  

  return {
    getData,
    updateData,
    create,
    updateFlagConfirmed,
    sendNewToken
  };
}