import React, { Fragment } from "react";

export const Error = () => {
  setTimeout(() => {
    window.location.href='/';;
  }, 2000);

  return (
    <Fragment>
      <h1>Oups, route inconnue !</h1>      
      <div>Retour à la page d'acceuil...</div>
    </Fragment>
   
  )  
};