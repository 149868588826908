import React, { Fragment, useState, useEffect } from "react";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; 

import dayjs from 'dayjs';
import "dayjs/locale/fr";

import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";
import {TextError , TextMessage, TextTip} from '#components/TextInformation';
import { useApiReservations } from "#services/apiReservations";         
import ScrollButtonToTop from '#components/ScrollButtonToTop'
import ConfirmDelete from '#components/ConfirmDelete';
import useFunctions  from '#utils/functions';

export function Reservations() {

    const {user} = useAuth();
    const api = useApiReservations();
  
    const {formatDate} = useFunctions();

    // const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);    

    // supprime une réservation -----------------------------------------------------------
    const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false);
    const [contentTextDeleteDialog, setContentTextDeleteDialog] = useState('');
    const [urvIdToDelete, setUrvIdToDelete] = useState(0);

    const handleRemoveClick = (e, row) => {
      e.stopPropagation();
      let txt = 'Voulez-vous vraiment supprimer la réservation de ' + row.urv_usr_firstname + ' ' + row.urv_usr_name;
      txt += ' pour un ' + row.urv_far_lib + ' ?';
      txt += "<br /><br />Date : " + formatDate(row.urv_date);
      txt += "<br />Horaire : " + row.urv_tis_lib;

      setUrvIdToDelete(row.urv_id);     
      setContentTextDeleteDialog(txt);
      setIsOpenConfirmDeleteDialog(true);
    };    

    const handleNoDelete = () => {      
      setUrvIdToDelete(0);     
      setIsOpenConfirmDeleteDialog(false);
      setContentTextDeleteDialog('');
    }
    const handleYesDelete = () => {
      setIsWorking(true);      
      let keyTab = refCodeTabs[activeTab].code;      

      api.deleteData(urvIdToDelete)
      .then ((json) => {          
          setIsWorking(false);           
          if (json.returnCode===0) {              
            setUrvIdToDelete(0);     
            setIsOpenConfirmDeleteDialog(false);
            setContentTextDeleteDialog('');        
            if (json.returnDataCount===1) {
              // refresh
              let tempL = {...isTabLoaded};
              tempL[keyTab]=false;
              setIsTabLoaded(tempL);
            }    
            setErrorMessage(null);
          } else {
              setUrvIdToDelete(0);     
              setIsOpenConfirmDeleteDialog(false);
              setContentTextDeleteDialog('');        
              setErrorMessage(json.returnMsg || 'Erreur suppression');
          }  
      });         
    }
    //--------------------------------------------------------------
    // définition des listes 

    dayjs().locale('fr');

    const refCodeTabs = [
      {code : 'today', lib : "Aujourd'hui", comment : "Réservation du " + formatDate(dayjs()) }
      ,{code : 'thisweek' , lib : "Cette semaine", comment : "Semaine du " + formatDate(dayjs().startOf('week')) + " au " + formatDate(dayjs().endOf('week')) }
      ,{code : 'nextweek', lib : "Semaine prochaine", comment : "Semaine du " + formatDate(dayjs().startOf('week').add(7,'day')) + " au " + formatDate(dayjs().endOf('week').add(7,'day')) }
      ,{code : 'future', lib : "A venir", comment : "A partir de demain" }
      ,{code : 'history', lib : "Historique"}  
    ]

    const [isTabLoaded, setIsTabLoaded] = useState({
      today: false,
      thisweek : false,
      nextweek : false,
      future: false,
      history: false
    });    
    const [data, setData] = useState({
      today : {},
      thisweek : {},
      nextweek : {},      
      future : {},
      history:{}
    });

    const baseColumns = [
      { field: 'urv_id', headerName: 'ID', width: 60, type : 'number', headerClassName: 'app-theme--header'},
      { field: 'urv_date', headerName: 'Date', width: 100, type :'date', headerClassName: 'app-theme--header'
        , valueFormatter: (params) => {
          return formatDate(params.value);
        }
      },                              
      { field: 'urv_tis_lib', headerName: 'Horaire', type : 'string',  width: 100 , headerClassName: 'app-theme--header'},                                    
      { field: 'urv_far_lib', headerName: 'Bureau', type : 'string',  width: 150, headerClassName: 'app-theme--header' },                                          
      { field: 'urv_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'urv_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'urv_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'},
      { field: 'actions', disableColumnMenu: true, resizable: false, filterable: false, sortable: false, editable: false, groupable: false,headerName: 'Actions'
        , width: 100, headerAlign : 'center', align : 'center', 
        renderCell: (params) => {
          return (
            <Box>
            <IconButton sx={{ ml: 0, px: 0, py:2, my:0 }}  onClick={(e) => handleRemoveClick(e, params.row)} >
              <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }}/>
            </IconButton>    
            </Box>        
          );
      } }
    ];

    const columns = {
      today : baseColumns,     // Aujourd'hui
      thisweek : baseColumns,  // semaine en cours
      nextweek : baseColumns,  // semaine prochaine
      future : baseColumns,    // A venir      
      history : baseColumns    // historique
    };            

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue, other) => {
      setActiveTab(newValue);
    };  

    const getRowId = (data) => {
        return data.urv_id;
    }

    useEffect(() => {     
      let keyTab = refCodeTabs[activeTab].code;

      if (user && !isTabLoaded[keyTab]) { 
        setIsWorking(true);
        api.getData(keyTab)
        .then ((json) => {          
          setIsWorking(false);
          let tempL = {...isTabLoaded};
          let tempD = {...data};
          tempL[keyTab]=true;
          if (json.returnCode===0) {     
            tempD[keyTab] = json.returnData;   
            setErrorMessage('');
          } else {            
            tempD[keyTab] = {};   
            setErrorMessage(json.returnMsg || 'Erreur récupération des réservations');        
          }  
          setData(tempD);       
          setIsTabLoaded(tempL);                 
        }); 
      }
      
    }, [user, activeTab, isTabLoaded]);

    //--------------------------------------------------------------        
    const getData = (tabId) => {
      let key = refCodeTabs[tabId].code; 
      return (data[key] && data[key].length>0) ? data[key] : null;
    }
    const getCol = (tabId) => {    
      let key = refCodeTabs[tabId].code;
      return (columns[key] && columns[key].length>0) ? columns[key] : null;
    }

    const getCommentActiveTab = (tabId) => {
      let key = refCodeTabs[tabId].comment;
      return key ? key : '';

    }

    return (        
        <Fragment>
          {/* pour la gestion de la suppression */}
          <ConfirmDelete open={isOpenConfirmDeleteDialog} contentText = {contentTextDeleteDialog} handleNo={handleNoDelete} handleYes={handleYesDelete}/>

          <TitlePage titlePage="Réservations" 
            showAddButton="right" tooltipAddButton="Ajouter une réservation" routeAddButton="/Admin/Reservation/Add"           
          />  
          
          {/* infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage> */}
          {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}
          
          <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
            
            {/* les tabs  */ }
            <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                {
                  refCodeTabs.map((value, index) => (      
                    <Tab key={index} label={value.lib} wrapped={true} />
                  ))
                }  
              </Tabs>
            </Box>
            <TextTip>{getCommentActiveTab(activeTab)}</TextTip>


            {/* les datas */}
            {!getData(activeTab) && isWorking && <Container align="center" sx={{ py : 5}}><CircularProgress /></Container> }   
            {!getData(activeTab) && !isWorking &&  <TextMessage>Aucune réservation</TextMessage>}   

            {getData(activeTab) && 
              <DataGrid
                loading={isWorking}
                getRowId={getRowId}
                rows={getData(activeTab)}
                columns={getCol(activeTab)}
                density='compact'
                disableColumnSelector={true}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                }}
                filterMode = 'client'
                pageSizeOptions={[25]}
                paginationMode="client"
              />
            }
          </Container>

          <ScrollButtonToTop />

        </Fragment>
    );    
};