import React from "react";
import Badge from '@mui/material/Badge';
import { green, blue, red } from '@mui/material/colors';

export default function BadgePackage(props) {

    const typePass = props.typePass ?? 'd';
    const isActive = props.isActive ?? false;
    const isRunning = props.isRunning ?? false;

    var legend = '-';

    switch (typePass) {            
        case 'd' :
            legend ='jours';
            break;
        case 'd2' :
            legend ='\u00bd jours';
            break;    
        case 'h' :
            legend ='heures';
            break;
        case 'h2' :
            legend ='\u00bd heures';
            break;
        case 'h4' :
            legend ='\u00bc heures';
            break;
        default :    
            legend = '-';
            break;
    }

    return (
        <Badge 
        sx={{ "& .MuiBadge-badge": {                             
            fontSize: 10,
            color : 'white', 
            backgroundColor: (isActive ? (isRunning ? green[400] : red[600])  : blue[300]),
            top:'110%', 
            right : '50%',
            textAlign : 'center',
            alignItems : 'top'
            } 
        }} 
        variant="string"         
        badgeContent={legend} 
        overlap="circular" 
        >
            {props.children}
        </Badge>
    );
}
