import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import styled from 'styled-components';

  
export const Button = styled.div`
   position: fixed; 
   right : 10px;
   bottom: 40px;
   height: 20px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
   color: aqua;
`
  
export default function ScrollButtonToTop() {
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisibleSB = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
 
  window.addEventListener('scroll', toggleVisibleSB);
  
  return (
    <Button>
     <FaArrowCircleUp onClick={scrollToTop} 
      style={{display: visible ? 'inline' : 'none'}} />
    </Button>
  );
}