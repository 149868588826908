import { useAuth } from '../utils/auth';

export function useApiAddPackage() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl;

  const getListAllowed = async function () {
    return fetch(resource + '/my_pricing', {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"

    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  };

  const addToBasket = async function (data, optionsSelected) {
    var dataToPost = {
      ubk_usr_id : auth.getId(),
      ubk_data : {
        type : 'upk',
        upkId : data.pri_id,
        upkOptionsSelected : optionsSelected,       
        upkData : data
      }
    }
    return fetch(resource + '/my_basket', {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "POST",
      body  : JSON.stringify(dataToPost)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  };

  const removeFromBasket = async function (id) {
    return fetch(resource + '/my_basket/' + id, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "DELETE"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }     
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;       
    })
  };

  const confirmFromBasket = async function (data, optionsSelected) {

    var priData = data.pri_data;
    priData.id = data.pri_id;
    priData.lib = data.pri_lib;
    // ajout du lib 
    var dataToPost = {
      upk_usr_id : auth.getId(),
      upk_pri_id : data.pri_id,
      upk_is_active : false,
      upk_data : {
          purchased: {
            date: (new Date()).toJSON(),
            optionsSelected : optionsSelected,
            priData: data.pri_data
          },
          status: {
            startDateValidity: "",
            endDateValidity: "",
            remainingPass: data.pri_data.nbPass
          },
          "usageHistory": [],
          "billingHistory": []          
        }
      }

    return fetch(resource + '/my_packages/', {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "POST",
      body  : JSON.stringify(dataToPost)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }     
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;       
    })
  }  

  return {
    getListAllowed,
    addToBasket,
    removeFromBasket,
    confirmFromBasket
  };
}
