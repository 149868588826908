import React, { Fragment, useState   } from "react";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Container from '@mui/material/Container';

import { useAuth } from "#utils/auth";
import { useFetch } from "#utils/fetch";

import { TitlePage } from "#components/TitlePage";
import {RequestReservation } from "#components/RequestReservation";


export function AddReservation() {
    const [showResult, setShowResult] = useState(false);

    const auth = useAuth();
    const {ok, data, message} = useFetch({
        resource : 'user',
        sorters : [{property:"usr_libr", direction: 'ASC'}],
        filters : [
            {property:"usr_is_active",value:1,operator:"=="},
            {property:"usr_is_accepted",value:1,operator:"=="}
        ]    
    });

    const [impersonatedUsrId, setImpersonatedUserId]  = useState({usr_id : auth.getId(), usr_libr : auth.getLibR() });

    return (
        <Fragment>
            { 
                showResult 
            ?  
                <TitlePage titlePage="Résultat de la réservation pour le compte de " />
            :
                <TitlePage titlePage="Ajouter une réservation pour le compte de " />
            }          
            <Container sx={{ pt: 2, pb : 2 }} align="center" maxWidth="xl">        
            { ok && <Autocomplete
                size="small"
                sx={{
                    minWidth : 200,
                    maxWidth : {
                        xs : 280,
                        sm : 450
                    }  
                }}
                disablePortal
                freeSolo = {false}
                options={data}
                getOptionLabel={(option) => option.usr_libr + ' (' + option.usr_id + ')'}
                renderInput={(params) => <TextField {...params} label="Choisissez un utilisateur" />}      
                value={impersonatedUsrId}
                onChange={(event, newValue) => {
                    if (!newValue) {
                        setImpersonatedUserId({usr_id : auth.getId(), usr_libr : auth.getLibR()});
                    } else {
                        setImpersonatedUserId(newValue);
                    }    
                }}    
            />
            }
            </Container>
            <RequestReservation showResult={showResult} setShowResult={setShowResult} impersonatedUsrId={impersonatedUsrId} />
        </Fragment>
    );    


}    