
import {useAuth} from '../utils/auth';

export function useApiMyPassword() {

  const auth = useAuth();    

  const resource = auth.apiBaseUrl + '/my_password'

  const updatePassword = async function (data) {
    return fetch(resource, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })

  };

  const createRequest = async function (data) {
    return fetch(resource, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "POST",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })

  };

  return {
    createRequest,
    updatePassword
  };
}