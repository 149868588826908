import React, { Fragment } from "react";

import { TitlePage } from "#components/TitlePage";
import ScrollButtonToTop from '#components/ScrollButtonToTop';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
//import Link from '@mui/material/Link';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
//import CardActions from '@mui/material/CardActions';
//import Button from '@mui/material/Button';

//import { useFetch } from "#utils/fetch";
//import { useAuth } from "#utils/auth";

const photos = [
  "La salle de pause du Médipôle entièrement accessible par les praticiens."
  ,"Un de nos deux postes de secrétariat avec les zones d’attente à proximité."
  ,"Les zones d’attente possèdent un écran affichant les praticiens du jour."
  ,"Le rooftop et son éclairage nocturne"
]

export function Photos() {
    //const auth = useAuth();

    return (
      <Fragment>
      <TitlePage titlePage="Galerie photos" />
      <Container sx={{ pt: 0, pb : 4, pl : 1, pr : 1  }} maxWidth="xl">
          <Grid container spacing={2}>
            {photos.map((legende, index) => (
              <Grid item key={"photo" + index} xs={12} sm={6} >
                <Card
                      sx={{ height: '100%', m:0, display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1, p : 0 }}>
                    <CardMedia
                      component="img"
                      image={require("#assets/galerie_photos_" + (index +1) + ".jpg")} 
                      />
                    <Typography sx={{ p: 1}}>
                      {legende}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))} 
          </Grid>    
      </Container>
      <ScrollButtonToTop />
      </Fragment>
    );
  }
