import React, { Fragment } from "react";
import useConfig from "#utils/config";

import { TitlePage } from "#components/TitlePage";
import Container from '@mui/material/Container';

export function MentionsLegales() { 
  const {siteName } = useConfig();

  return (  
    <Fragment>
      <TitlePage titlePage="Mentions légales" />
      <Container sx={{ m:0, pt: 0, pb : 2 }} maxWidth="xl" >                          
      <h3>{siteName}</h3>
      Raison sociale : Médipôle-Le-Square
        <br />Forme juridique : SAS, société par actions simplifiée
        <br />Date de création : 07/09/2022
        <br />SIREN : 919 046 870
        <br />SIRET : 919 046 870 00011
        <br />Siège social : 3 rue du premier de ligne, 59400 CAMBRAI
        <br />Code APE / NAF : 68.20B - Locations de terrains et d'autres biens immobiliers
        <br />Editeur du service : {siteName}
        <br />Directeurs de la publication et de la rédaction : Médipôle-Le-Square
      </Container>
    </Fragment>
  );
};