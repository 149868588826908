import { useAuth } from '../utils/auth';

export function useApiMyDocuments() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/my_documents'

  const getData = async function (isGeneral) {

    var filters = JSON.stringify(
      [
        { property : "udc_is_general", value : (isGeneral?1:0)} 
      ]
    );
    var sorters = JSON.stringify(
      [
        { property:"udc_upload_time", direction:"DESC"}
      ]
    );
    return fetch(resource + '?filters=' + filters + '&sorters=' + sorters , {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"

    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }          
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;        
    })
  };

  const getDocLink = async function (udc_id) {
    return fetch(resource + '/' + udc_id + '/getdoclink', {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }     
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;             
    })
  };


  return {
    getData,
    getDocLink
  };
}
