import React from "react";

import Container from '@mui/material/Container';

export const TextPageCenter = (props) => {

    const textAlign = props.textAlign ||'justify'

    return (
        <Container sx={{ px:"0.5em", py:"1em", textAlign:{textAlign} }} maxWidth="md" >        
            {props.children}
        </Container>
    );
};
