import React, {useEffect} from "react";

export const HtmlSpan = (props) => {

    const spanRef = React.createRef();

    useEffect(() => {         
        spanRef.current.innerHTML = props.text;
    }, [props]);         

    return (        
        <span ref={spanRef}></span>
    );
};
