import React from "react";

//import Box from '@mui/material/Box';
import Container from '@mui/material/Container'

export const TextError = (props) => {

    const textAlign = props.textAlign ||'center';
    const color = props.color ||'red';
    const fontSize = props.fontSize || '1.2em';
    const fontWeight = props.fontWeight || 600;  
    const backgroundColor = props.backgroundColor || '#FFEEEE';

    return (
        <Container component="span" sx={{ 
            px : "0.5em", 
            py : "1em", 
            mt : 1,
            mb : 1,
            textAlign:{textAlign} ,
            color:{color},
            backgroundColor : {backgroundColor},
            fontSize : {fontSize},
            fontWeight : {fontWeight},            
            borderRadius : '25px'
        }} 
        maxWidth="md" >            
            {props.children}
        </Container>
    );
};

export const TextMessage = (props) => {

    const textAlign = props.textAlign ||'center';
    const color = props.color ||'blue';
    const fontSize = props.fontSize || '1em';
    const fontWeight = props.fontWeight || 400;  
    const backgroundColor = props.backgroundColor || '#EEEEEE';

    return (
        <Container sx={{ 
            px : "0.5em", 
            py : "1em", 
            mt : 1,
            mb : 1,
            textAlign:{textAlign} ,
            color:{color},            
            backgroundColor : {backgroundColor},
            fontSize : {fontSize},
            fontWeight : {fontWeight},
            borderRadius : '25px'
        }} 
        maxWidth="md" >        
            {props.children}
        </Container>
    );
};

export const TextTip = (props) => {

    const textAlign = props.textAlign ||'left';
    const color = props.color ||'gray';
    const fontSize = props.fontSize || '1em';
    const fontWeight = props.fontWeight || 400;  
    const backgroundColor = props.backgroundColor || '#EFEFEF';
    const borderRadius = props.borderRadius || '0px';

    return (
        props.children && 
        <Container sx={{ 
            px : "0.1em", 
            py : "0.3em",             
            mt : 1,
            mb : 2,
            textAlign:{textAlign} ,
            color:{color},            
            backgroundColor : {backgroundColor},
            fontSize : {fontSize},
            fontWeight : {fontWeight},
            borderRadius : {borderRadius}
        }} 
        // maxWidth="md" 
        >        
            {props.children}
        </Container>
    );
};
