import React, { Fragment } from "react";

import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";


export function Resources() {
    return (
        <Fragment>
        <TitlePage titlePage="Ressources" />  
        </Fragment>
    );    
};