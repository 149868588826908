import React,  {Fragment, useState, useEffect }  from "react";

import { useAuth } from '#utils/auth';
import {useSearchParams} from 'react-router-dom';

import {useApiMyAccount} from "#services/apiMyAccount";

import { TitlePage } from "#components/TitlePage";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { PasswordField } from "#components/PasswordField";
import { TextMessage, TextError } from "#components/TextInformation";
import {HtmlSpan} from "#components/HtmlSpan";
import Button from '@mui/material/Button';


export function Signup() {
    const auth = useAuth();    

    const api = useApiMyAccount();
    const [queryParameters] = useSearchParams()

    //const [isLoaded, setIsLoaded] = useState(false);
    const [isRequestOk, setIsRequestOk] = useState(false);

    //const [profileData, setProfileData] = useState({})
    const [isConfirmEmailOk, setIsConfirmEmailOk] = useState(false);    

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [errors, setErrors] = useState({})

    const [token, setToken] = useState(null);

    useEffect(() => {            
      setToken(queryParameters.get('token'));
      //console.log(token);
      if (auth && token) {        
        doConfirm();
      }
    }, [auth, token]);

    const doConfirm = (event) => {
      if (!token && !auth) return;
      // update le flag is_corfirmed            
      let data = {
        usr_request_token : token, 
        usr_is_confirmed :true 
      };
      api.updateFlagConfirmed(data)
        .then ((json) => {                    
          //console.log(json);
          if (json.returnDataCount>0) {
            setErrorMessage('');
            setIsConfirmEmailOk(true);            
            setInfoMessage('Votre adresse mail est confirmée.<br />Vous pouvez dès maintenant vous connecter.');
          } else {
            setInfoMessage('');
            setErrorMessage('Votre adresse mail ne pas être confirmée<br /><br />' + (!!json.returnMsg ? json.returnMsg : 'Problème de jeton.') + '<br /><br />Cliquez sur le bouton ci-dessous<br />pour obtenir un nouveau jeton.');
            setIsConfirmEmailOk(false);            
        }
      });            
    }  

    const handleSubmit = (event) => {
        event.preventDefault();        
        const dataForm = new FormData(event.currentTarget);
                
        const data = Object.fromEntries(dataForm.entries());
        let eMesg = '';
        let iMesg = '';
        let newErrors={};
      
        if(!token) {        
          if (!data.usr_email) newErrors.usr_email='Votre email est obligatoire';
          if (!data.usr_idrpps) newErrors.usr_idrpps='Votre Id RPPS / Adelie est obligatoire';                
          if (!data.usr_firstname) newErrors.usr_firstname="Votre prénom est obligatoire";
          if (!data.usr_name) newErrors.usr_name="Votre nom est obligatoire";        
          if (!data.usr_password) newErrors.usr_password="obligatoire !!";
        }

        setErrors(newErrors);

        // si pas d'erreurs on update
        if (Object.entries(newErrors).length === 0 && newErrors.constructor === Object) {        
          //console.log(Object.fromEntries(dataForm.entries()));          
          if (!!token) {
            console.log('renvoi token');    
            // update le flag is_corfirmed            
            let data = {
              usr_request_token : token, 
              usr_request_new_token :true 
            };        
            api.sendNewToken(data)
              .then ((json) => {                    
                //console.log(json);
                if (json.returnDataCount>0) {
                  setIsConfirmEmailOk(true);
                  iMesg = 'Vous allez recevoir un email pour confirmer votre adresse<br />Surveillez votre messagerie ... <br />Sans oublier de vérifier vos indésirables !';
                  setInfoMessage(iMesg);
                } else {
                  eMesg = (!!json.returnMsg) ? json.returnMsg : 'Envoi d\'un nouveau jeton impossible.';        
                  setErrorMessage(eMesg);          
                  setIsConfirmEmailOk(false);            
              }
            });
                        
          } else {  
            // create 
            api.create(data)
              .then ((json) => {             
                //console.log(json);       
                if (json.returnDataCount>0) {
                  setIsRequestOk(true);     
                  iMesg = 'Votre compte est créé.<br />Vous allez recevoir un email pour confirmer votre adresse<br />Surveillez votre messagerie ... <br />Sans oublier de vérifier vos indésirables !';                         
                  setInfoMessage(iMesg);
                } else {    
                  eMesg = (!!json.returnMsg) ? json.returnMsg : 'Aucun compe créé';
                  setErrorMessage(eMesg);
                  setIsRequestOk(false);                              
                }
              });
          }

        } else {
          eMesg = 'Certaines données sont incomplètes ou erronées !';          
          setErrorMessage(eMesg);
        }

        setInfoMessage(iMesg);
        setErrorMessage(eMesg);        
    };      
  

    return (
        <Fragment>
        <TitlePage titlePage="Créer mon compte" />  

        <Container sx={{ pt: 0, pb : 4 }} maxWidth="sm">

          {infoMessage!=='' &&  <TextMessage><HtmlSpan text={infoMessage} /></TextMessage>}
          {(errorMessage!=='') &&  <TextError><HtmlSpan text={errorMessage} /></TextError>}

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>      
          {!token 
            ? (isRequestOk ? "" : 
              <>
              <TextField
                margin="normal"            
                required
                error={!!errors.usr_email}
                helperText={errors.usr_email} 
                fullWidth
                size="small"
                id="usr_email"
                label="Votre Email"
                name="usr_email"
                //defaultValue={profileData.usr_email}      
                autoComplete="email"
                type="email"
                autoFocus
              />

              <TextField
                margin="normal"            
                required
                error={!!errors.usr_idrpps}
                helperText={errors.usr_idrpps} 
                fullWidth
                size="small"
                name="usr_idrpps"
                label="Votre identifiant RPPS / Adélie"
                type="number"
                id="usr_idrpps"
              />

              <TextField
                margin="normal"
                required       
                error={!!errors.usr_firstname}
                helperText={errors.usr_firstname} 
                fullWidth     
                size="small"
                name="usr_firstname"
                label="Votre prénom"
                id="usr_firstname"   
              />
              <TextField
                margin="normal"
                required
                error={!!errors.usr_name}
                helperText={errors.usr_name} 
                fullWidth
                size="small"
                name="usr_name"
                label="Votre nom"
                id="usr_name"
              />

              <PasswordField
                label="Mot de passe"
                required
                error={!!errors.usr_password}
                helperText={errors.usr_password} 
                margin="normal"
                size="small"
                fullWidth
                name="usr_password"
                id="usr_password"
                type="password"
                autoComplete="new-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              > 
              Créer votre compte
              </Button>
              </>
            )
          : 
            (isConfirmEmailOk ? "" : <>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              > 
              Renvoyer un email avec un nouveau jeton
              </Button>

            </>)
          }    

          </Box>



        </Container>
        </Fragment>
      );
}