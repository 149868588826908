import React from "react";

import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

const dataFamilyTypePackage = [
        { id: 1, lib: "Tarif IDE"},
        { id: 2, lib: "Tarif non IDE"}
];  
    
export const SelectFamillyTypePackage = (props) => {

    return (
        <Select {...props}>
        <MenuItem value=""><em>--</em></MenuItem>
        {
            dataFamilyTypePackage.map((value, index) => (                
                value.lib && <MenuItem key={index} id={index} value={value.lib}>{value.lib}</MenuItem>                 
            ))
        }       
        </Select>     
   );
}    
