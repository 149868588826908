import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";
import { LoadingIcon } from "#components/Loading";
import {TextError , TextMessage} from '#components/TextInformation';

import { useApiAccounts } from "#services/apiAccounts";

import ScrollButtonToTop from '#components/ScrollButtonToTop'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import useFunctions  from '#utils/functions';

export function Accounts() {

    const {user} = useAuth();
    const api = useApiAccounts();

    const {formatDatetime} = useFunctions();    

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);    

    const [isLoaded, setIsLoaded] = useState({tab0: false, tab1: false, tab2: false } );
    const [data, setData] = useState({data0 : {}, data1:{}, data2:{}})    
    
    //--------------------------------------------------------------        
    const columns = {
      // comptes actif
      cd0 : [
      { field: 'usr_id', headerName: 'ID', width: 70, type : 'number',  headerClassName: 'app-theme--header'},
      { field: 'usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' },
      { field: 'usr_can_login', headerName: 'Con. Autorisée', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},
      { field: 'usr_time_last_login', headerName: 'Dern. login', type : 'dateTime',  width: 160, headerClassName: 'app-theme--header' 
        , valueFormatter: (params) => {
          return formatDatetime(params.value);
        }
      }
      ],
      // comptes en attente
      cd1 : [
      { field: 'usr_id', headerName: 'ID', width: 70, type : 'number', headerClassName: 'app-theme--header' },
      { field: 'usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header' },
      { field: 'usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header' },
      { field: 'usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' },
      { field: 'usr_is_confirmed', headerName: 'Email Conf.', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },
      { field: 'usr_is_accepted', headerName: 'Accepté', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },            
      { field: 'usr_can_login', headerName: 'Con. Autorisée', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },
      { field: 'usr_time_last_login', headerName: 'Dern. login', type : 'dateTime',  width: 160, headerClassName: 'app-theme--header'
        , valueFormatter: (params) => {
          return formatDatetime(params.value);
        }
      },                        
      ],
      // comptes non actif
      cd2 : [
        { field: 'usr_id', headerName: 'ID', width: 70, type : 'number', headerClassName: 'app-theme--header'},
        { field: 'usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' },
        //{ field: 'usr_can_login', headerName: 'Con. Autorisée', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},
        { field: 'usr_time_last_login', headerName: 'Dern. login', type : 'dateTime',  width: 160, headerClassName: 'app-theme--header' 
          , valueFormatter: (params) => {
            return formatDatetime(params.value);
          }
        }
        ],
  
    };           

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
      setActiveTab(newValue);
    };  

    const getRowId = (data) => {
      return data.usr_id;
    }    

    useEffect(() => {      
      if (user && !isLoaded["tab" + activeTab]) { 
        setIsWorking(true);
        api.getData(activeTab)
        .then ((json) => {          
          setIsWorking(false);
          let tempL = {...isLoaded};
          let tempD = {...data};
          tempL["tab" + activeTab]=true;
          if (json.returnCode===0) {     
            tempD["data" + activeTab] = json.returnData;   
            setErrorMessage("");
          } else {            
            tempD["data" + activeTab] = {};   
            setErrorMessage(json.returnMsg || 'Aucun compte utilisateur');
        
          }  
          setData(tempD);       
          setIsLoaded(tempL);                 
        }); 
      }
      
    }, [user, activeTab, isLoaded]);
    
    const getData = (tabId) => {
      let key = "data" + tabId;
      return (data[key] && data[key].length>0) ? data[key] : null;
    }
    const getCol = (tabId) => {
      let key = "cd" + tabId;
      return (columns[key] && columns[key].length>0) ? columns[key] : null;
    }

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
      console.log(filterModel);
      
    }, []);

    return (        
      <Fragment>
        <TitlePage titlePage="Comptes utilisateurs" />  
        
        {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
        {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

        <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
          {/* les tabs  */ }
          <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab}>
              <Tab label="Actif" />
              <Tab label="En attente" />
              <Tab label="Non actif" />
              </Tabs>
          </Box>

          {/* les datas */}
          {!getData(activeTab) && <Container align="center" sx={{ py : 5}}><CircularProgress /></Container>}                      
          {getData(activeTab) && 
            <DataGrid
              loading ={isWorking}
              getRowId={getRowId}
              rows={getData(activeTab)}
              columns={getCol(activeTab)}
              density='compact'
              disableColumnSelector={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              filterMode = 'client'
              onFilterModelChange={onFilterChange}
              pageSizeOptions={[10]}
            />
          }
        </Container>

        <ScrollButtonToTop />

      </Fragment>
    );    
};