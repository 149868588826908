import React from "react"
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { Link as RouterLink } from 'react-router-dom';


const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

export const themeMUI = responsiveFontSizes( createTheme({
    
    components: {
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          },
        },  
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },

        MuiTab: {
          styleOverrides : {
            root : {
              fontSize : "0.8rem"
            }
          }
        }  
    },
}));

