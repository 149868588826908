import React from "react";

import Container from '@mui/material/Container';
import { Outlet} from "react-router-dom";

 const Content = () => {
  return (
    <Container sx={{ mt: 10, pl:0, pr: 0, }} >
      <Outlet />
    </Container>
  );
};

export default Content;
