import React, { Fragment, useState, useEffect  } from "react";

import { useParams } from "react-router-dom";

import { useApiAddReservation } from "../services/apiAddReservation";

import useConfig from "#utils/config";
import { useAuth } from '#utils/auth';
import useFunctions  from '#utils/functions';

import { LoadingIcon } from "#components/Loading";
import ScrollButtonToTop from '#components/ScrollButtonToTop';
import { TitlePage } from "#components/TitlePage";
import {TextError , TextMessage} from '#components/TextInformation';
import { HtmlSpan } from './HtmlSpan';
import ListAddReservation from '#components/ListAddReservation';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "dayjs/locale/fr";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//import { fr } from '@mui/x-date-pickers/locales';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ToggleButton from '@mui/material/ToggleButton';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { red } from '@mui/material/colors';

//----------------------------------------------------------------------------------------------------------------
export function RequestReservation({showResult, setShowResult, impersonatedUsrId }) {

  //const addParams = useParams();
  //console.log(addParams, showResult, impersonatedUsrId);
  
  const cfg = useConfig();
  const {user} = useAuth();
  const api = useApiAddReservation();
  const {isValidDate, countDaysLeft} = useFunctions();  

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [config, setConfig] = useState(null); // data config

  const [isAvailabilityLoaded, setIsAvailabilityLoaded] = useState(true);
  const [dataAvailability, setDataAvailability] = useState(null);

  const [isConfirmLoaded, setIsConfirmLoaded] = useState(true);
  const [dataConfirm, setDataConfirm] = useState(null);

  //const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const [openDP,setOpenDP] = useState(false);

  const [farId, setFarId] = useState(0);

  const [dataUpk, setDataUpk] = useState(null);
  const [upkId, setUpkId] = useState(0);

  const [urvDate, setUrvDate] = useState(null);
  const [showCannotDelete, setShowCannotDelete] = useState(false);    

  const [dataDas, setDataDas] = useState(null) ;  // data sur das selon le contexte
  const [dataTis, setDataTis] = useState(null) ;  // data sur tis selon le contexte
  const [tisSelected, setTisSelected] = useState([]); //   

//  const [urvTisId, setUrvTisId] = useState(0);

  // changement de resource (type bureau)
  function handleChangeResource(value) {
    clearAvailability();
    setFarId(value);

    let tab = config.find((far) => far.id===value);
    setDataUpk(tab.upk);
    setUpkId(tab.upk[0].id);
    setDataDas(tab.upk[0].das);
    setDataTis(tab.upk[0].tis);
  }

  // changement package (abonnment)
  function handleChangePackage(value) {
    clearAvailability();
    setUpkId(value);

    let tab = dataUpk.find((upk) => upk.id===value);    
    setDataDas(tab.das);
    setDataTis(tab.tis);
  }

  // changement de date
  function handleChangeDate(newValue) {
      clearAvailability();
      setUrvDate(newValue);
      // annulation possible ?
      if (isValidDate(newValue.toDate())) {
        // on utilise toDate() car la valeur provient dayjs et la fonction utilise une date js
        setShowCannotDelete(countDaysLeft( new Date(), newValue.toDate())<cfg.defaultValues.nbDaysCanDeleteUrv) ;
      } else {
        setShowCannotDelete(false) ;
      }  
      //console.log("date : " + new Date(newValue.toDate()).toLocaleDateString("fr"));
  }

  function handleChangeTis(event, value) {
    clearAvailability();

    let tmpArray = [...tisSelected];
    let index = tmpArray.indexOf(value);    
    if (index!==-1) {
      tmpArray.splice(index, 1);
    } else {
      tmpArray.push(value);
    }
    setTisSelected(tmpArray);
  }

  function disableDate (date) {
    // TODO: prendre en compte les jours fériés !
    return dataDas.indexOf(dayjs(date).day()) ===-1; 
  }

  function clearAvailability() {
    setTisSelected([]);    
    setDataAvailability(null);
  }
  
  function handleFindAvailability() {
    var data = {
      farId : farId,
      upkId : upkId,
      urvDate : urvDate,
      tisSelected : tisSelected
    }
    setDataAvailability(null);
    setIsAvailabilityLoaded(false); 
    api.getAvailability(data, impersonatedUsrId)   
    .then ((json) => {          
      //console.log(json);          
      if (json.returnCode===0 && json.returnDataCount>0) {                    
        setIsAvailabilityLoaded(true); 
        setDataAvailability(json.returnData);
        setErrorMessage('');             
      } else {            
        setIsAvailabilityLoaded(true);
        setDataAvailability(null);
        setErrorMessage(json.returnMsg || 'Aucune disponibilité.<br/><br/>Réservation impossible');
      }  
    }); 
  }

  function handleConfirmReservation() {
    var data = {
      farId : farId,
      upkId : upkId,
      urvDate : urvDate,
      tisSelected : tisSelected
    }

    api.confirmReservation(data, impersonatedUsrId)   
    .then ((json) => {          
      //console.log(json);      
      setShowResult(true);
      if (json.returnCode===0 && json.returnDataCount>0) {                    
        //console.log(json.returnData);
        setIsConfirmLoaded(true);
        setDataConfirm(json.returnData);        
        setErrorMessage('');             
      } else {            
        setIsConfirmLoaded(false);
        setDataConfirm(null);
        setErrorMessage(json.returnMsg || 'Pb avec la réservation.<br/><br/>Réservation impossible');
      }  
    }); 
  }

  function handleNewReservation() {
    setShowResult(false);
    setIsConfigLoaded(false);
  }

  useEffect(() => {      
    (user && (!isConfigLoaded || impersonatedUsrId)) && 
      api.getConfigForUser(impersonatedUsrId)
        .then ((json) => {          
          //console.log(json);          
          if (json.returnCode===0 && json.returnDataCount>0) {                    
            setConfig(json.returnData.far);
            setFarId(json.returnData.far[0].id);
            setDataUpk(json.returnData.far[0].upk);
            setUpkId(json.returnData.far[0].upk[0].id);
            setDataDas(json.returnData.far[0].upk[0].das);
            setDataTis(json.returnData.far[0].upk[0].tis);
            setUrvDate(null);    
            setTisSelected([]);    
            setDataAvailability(null);
            setDataConfirm(null);

            setIsConfigLoaded(true); 
            setErrorMessage('');             
          } else {            
            setConfig(null);
            setIsConfigLoaded(true);
            setErrorMessage(json.returnMsg || 'Aucun abonnement actif trouvé !<br/><br/>Réservation impossible');
          }  
        }); 
  }, [isConfigLoaded, user, impersonatedUsrId]);


  return (
    <Fragment>

      {!isConfigLoaded && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}

      { /* infoMessage!=='' &&  <TextMessage><HtmlSpan text={infoMessage} /></TextMessage> */}
      {errorMessage!=='' &&  <TextError><HtmlSpan text={errorMessage} /></TextError>}

      {isConfigLoaded && config && !dataConfirm &&
        <Container sx={{ pt: 4, pb : 4 }} align="center" maxWidth="xl">
          {!impersonatedUsrId 
            ?            
            <Typography component="p" variant="body" sx={{mb:5, textAlign:"center", fontWeight:'600' }}>
              Les choix proposés dépendent de vos abonnements disponibles
            </Typography>
            :
            <Typography component="p" variant="body" sx={{mb:5, textAlign:"center", fontWeight:'600' }}>
              Les choix proposés dépendent des abonnements disponibles pour l'utilisateur sélectionné
            </Typography>
          }        
          <Grid container rowSpacing={2} columnSpacing={1} alignItems="center">
            { /* resource ? */}
            <Grid item xs={4} sm={6} sx={{textAlign : "right"}}>
              <Typography variant='body'>
              Que souhaitez réserver ?
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6} sx={{textAlign : "left"}} >
              <Select
                size="small"
                sx={{
                  minWidth : 180,                  
                  maxWidth : {
                    xs : 230,
                    sm : 400
                  }  
                }}
                value={farId}
                onChange={(selection) => handleChangeResource(selection.target.value)}
              >
                {config && config.map((far, index) => (                      
                  <MenuItem key={far.id + ':' + index} value={far.id}>{far.lib}</MenuItem>      
                ))}
              </Select>
            </Grid>

            {/* package ? */}
            <Grid item xs={4} sm={6} sx={{textAlign : "right"}}>
              <Typography variant='body'>
              Avec quel abonnement ?
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6} sx={{textAlign : "left"}} >
              <Select
                size="small"
                sx={{
                  minWidth : 180,
                  maxWidth : {
                    xs : 230,
                    sm : 400
                  }  
                }}
                value={upkId}
                onChange={(selection) => handleChangePackage(selection.target.value)}
              >
                {config && dataUpk && dataUpk.map((upk, index) => (                      
                  <MenuItem key={farId +'/' + upk.id} value={upk.id}>{upk.lib}</MenuItem>      
                ))}

              </Select>
            </Grid>


            { /* Date */}      
            <Grid item xs={4} sm={6} sx={{textAlign : "right"}}>
              <Typography variant='body'>
                A quelle date ?
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6} sx={{textAlign : "left"}}>
              <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
                <DatePicker     
                  open={openDP}
                  onClose={() => setOpenDP(false)} 
                  closeOnSelect={true}
                  displayWeekNumber={false}
                  sx = {{
                    width : 180 
                  }}  
                  slotProps={{ 
                    textField: { 
                      size: 'small' ,
                      readOnly: true,                      
                      onClick: () => setOpenDP(true)
                    }  
                  }}                
                  onChange={(newValue) => handleChangeDate(newValue)}         
                  shouldDisableDate={disableDate}
                  label="JJ/MM/AAAA"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}       
                />

              </LocalizationProvider>
            </Grid>
            {/* annulation possible ? */}
            <Grid item xs={12} sx={{textAlign : "center"}}>
            {showCannotDelete && 
              <Typography gutterBottom component="div" variant="body" sx={{fontSize: 12, px: 0.5, py:1, fontWeight:700, color:red[600], backgroundColor:red[100], mb:0}}>
                ATTENTION ! Annulation impossible pour cette date.
              </Typography>
            }
            </Grid>

          </Grid>

          { /* Tis */ }        
          <Grid container rowSpacing={0} columnSpacing={0} alignItems="center" sx={{mt:2}}>    
          {
            (dataTis && dataTis.length>0) && (
            <>
              <Grid item xs={12} sx={{textAlign : "center", mb : 2}}>
                <Typography variant='body'>
                  Pour quel(s) créneau(x) horaire(s) ?
                </Typography>
              </Grid>
              <Grid item 
                xs={12} 
                padding={0}
                sx={{
                  textAlign : "center",
                  px:0,
                  m: 0
                }}
              >
                {dataTis.map((value, index) => (                           
                    <ToggleButton 
                      sx={{my:0.5, mx : 0.5, px : 0, py :0.5, width : 100 }} 
                      value={value.id} 
                      key={value.id}
                      selected={tisSelected.indexOf(value.id)!==-1}
                      onChange={handleChangeTis}
                    >
                      {value.lib}
                    </ToggleButton >
                ))}
              </Grid>

              {/*
              <Grid item xs={6} sx={{textAlign : "right"}} >
              <ToggleButtonGroup
                  sx = {{
                    width : { xs : '100%', sm:200}
                  }}
                  orientation="vertical"
                  value={tisSelected}
                  size="small"                    
                  onChange={handleChangeTis}
                >                
                  {dataTis.map((value, index) => (       
                    
                    parseInt(value.periode)===1 && <ToggleButton sx={{my:1 }} value={value.id} key={value.id}>{value.lib}</ToggleButton >
                  ))}
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={6} sx={{textAlign : "left"}} >    
                <ToggleButtonGroup                    
                  sx = {{
                    width : { xs : '100%', sm:200}
                  }}                    
                  orientation="vertical"
                  value={tisSelected}                    
                  size="small" 
                  onChange={handleChangeTis}
                >
                  {dataTis.map((value, index) => (                      
                    parseInt(value.periode)===2 && <ToggleButton sx={{my:1}} value={value.id} key={value.id}>{value.lib}</ToggleButton >
                  ))}
                </ToggleButtonGroup>       
              </Grid>
              */}      
            </>)
          }
          </Grid>

          {/* validation */}
          { farId && upkId && urvDate && tisSelected && tisSelected.length>0 &&
          <Box >     
              <Button              
                disabled={!!dataAvailability} 
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleFindAvailability}
              >Rechercher les disponibilités</Button>

            {!isAvailabilityLoaded && <Container align="center" sx={{ py : 1}}><LoadingIcon /></Container>}
            {isAvailabilityLoaded && dataAvailability && 
              <>
                <ListAddReservation data={dataAvailability} />
                <Button               
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}      
                  onClick={handleConfirmReservation}
                >Confirmer la réservation</Button>
              </>
            }

          </Box>
          }
        </Container>
      }

      {!isConfirmLoaded && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}

      { isConfirmLoaded && dataConfirm && 
        <Container sx={{ pt: 4, pb : 4 }} align="center" maxWidth="xl">                         
          <ListAddReservation data={dataConfirm} />
          <Button               
            variant="contained"
            sx={{ mt: 3, mb: 2 }}      
            onClick={handleNewReservation}
          >Effectuer une autre réservation</Button>
          <br />
          {!impersonatedUsrId &&
            <Button               
              variant="contained"
              sx={{ mt: 3, mb: 2 }}      
              to="/MyReservations"
            >Voir mes réservations</Button>
          }  
        </Container>
      }
      <ScrollButtonToTop />
    </Fragment>
  );

};