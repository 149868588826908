import { useAuth } from '../utils/auth';

export function useApiMyReservations() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/my_reservations'

  const getData = async function (filter, page) {

    var aFilters = '', aSorters ='';
    page = page || 1;
  
    // A venir
    if (filter===0) {
      aFilters = [{property:"urv_status",value:0,operator:"=="}];
      aSorters = [{property:"urv_date",direction:'ASC'},{property:"urv_tis_id",direction:"ASC"}];
    }
  
    // Historique
    if (filter===1) {
      aFilters = [{property:"urv_status",value:100,operator:"=="}];
      aSorters = [{property:"urv_date",direction:'DESC'},{property:"urv_tis_id",direction:"ASC"}];
    }

    return fetch(resource + '?filters=' + JSON.stringify(aFilters) + '&sorters=' + JSON.stringify(aSorters), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
        return {          
          returnCode : -500,            
          returnMsg : (error.response || error.request || error.message)
        }  
    })
  };

  const updateData = async function (data) {
    return fetch(resource + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;
    
    })
  };

  const getHistoForUpk = async function (upkId) {
    return fetch(resource + '/history_upk/' +  upkId, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;    
    })
  };


  return {
    getData,
    updateData,
    getHistoForUpk
  };
}
