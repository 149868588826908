import React, { useState, useContext, createContext } from "react";
import useConfig from "#utils/config";

const authContext = createContext();

export function AuthProvider({children }) {
    const auth = useProvideAuth();
    return  <authContext.Provider value={auth}>
        {children}
        </authContext.Provider>
        ;
}

export const useAuth = () => { 
    return useContext(authContext);
}

function useProvideAuth() {
    var jwt;

    const {apiBaseUrl, app,  anonymousLogin, anonymousPassword} = useConfig();
    
    const [user, setUser] = useState(null);
    const [forcedUserId, setForcedUserId] = useState(null); // utilisateur imité
    
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setInit] = useState(false);

    function setLocalStorage(user) {
        //console.log('save to storage');
        localStorage.setItem(app.code +'_user', JSON.stringify(user));
        setInit(true);
    }

    function clearLocalStorage() {
        localStorage.removeItem(app.code +'_user');
        setInit(false);
    }

    function initFromLocalStorage() {
        //console.log('init from storage');
        const tokenString = localStorage.getItem(app.code + '_user');
        const userData = JSON.parse(tokenString);        
        if (userData?.token) { 
            // verif expiration ?
            let ts = Math.round(new Date().getTime()/1000);
            // refresh ?
            if (userData.exp + (60*60) < ts) {
                // tentative de refresh dans la dernière heure                
                refresh();
            } else if (userData.exp <ts) {
                signin(anonymousLogin, anonymousPassword);     
            } else {
                setUser(userData);            
                setInit(true);
            }    
        } else {
            //console.log('pas de token');
            signin(anonymousLogin, anonymousPassword); 
        }        
    }

    // login
     function signin(username, password) {
        if (isLoading) return;
 //console.log('SIGNIN', username);
        setIsLoading(true);
        fetch(apiBaseUrl + "/token", {
            signal : AbortSignal.timeout(30000),
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
                "MadeBy64-Token" :  app.ctxToken || ""
            },
            body: JSON.stringify({username, password})
        }).then((response) => {
            setIsLoading(false);                 
            if (response.ok) {                
                return response.json();
            } else {                
                //setError('(' + response.status + ') ' + response.statusText);     
                return response.json();  // pour récup returnMsg
            }
        }).then( (data) => {            
            if (data.returnCode===0) {                   
                if (decodeJwt(data.returnData.token)) {            
                    //console.log(jwt);
                    setUser(data.returnData);
                    setLocalStorage(data.returnData);
                } else {
                    setUser(null);                   
                    clearLocalStorage();
                    setError('JWT error - no token');         
                }    
            } else {
                setUser(null);            
                setLocalStorage(null);       
                setError(data.returnMsg);     
                return
            }
        }).catch( (err) => {
            setIsLoading(false);                 
            setError('Identification impossible (' + err.message +')');     
        });
        
    }

    function refresh() {
        if (isLoading) return;
        setIsLoading(true);
        if (user && user.token) {
            fetch(apiBaseUrl + "/token/refresh", {
                signal : AbortSignal.timeout(30000),
                method: "GET",
                headers: {
                    "Content-Type" : "application/json",
                    "MadeBy64-Token" :  app.ctxToken || "",
                    "Authorization": "Bearer " + (user ? user.token : "")                    
                }            
            }).then((response) => {
                setIsLoading(false);                 
                if (response.ok) {                
                    return response.json();
                } else {                
                    return response.json();  // pour récup returnMsg
                }
            }).then( (data) => {            
                if (data.returnCode===0) {                   
                    if (decodeJwt(data.returnData.token)) {            
                        if (decodeJwt(data.returnData.token)) {            
                            setUser(data.returnData);
                            setLocalStorage(data.returnData);
                        } else {
                            setUser(null);                   
                            clearLocalStorage();
                            setError('JWT error - no token');         
                        }
                    }    
                } else {
                    setUser(null);            
                    setLocalStorage(null);                    
                    setError(data.returnMsg);     
                    signin(anonymousLogin, anonymousPassword); 
                    return
                }
            }).catch( (err) => {
                setIsLoading(false);                 
                setError('Identification impossible (' + err.message +')');     
            });
        } else {
            signin(anonymousLogin, anonymousPassword); 
        }
        
    }


    function decodeJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        try {
            jwt = JSON.parse(jsonPayload);            
            //console.log(jwt);           
            return true; 
        } catch (e) {
            jwt =null;
            return false;
        }    
    }

    // user confirmed
    function isConfirmed() {      
        //console.log('----> user is confirmed :',user && user.token && decodeJwt(user.token) );
        if (user && decodeJwt(user.token)) {
            return jwt.user.isConfirmed;  
        } else {
            return false;
        }    
    }    

    // user accepted
    function isAccepted() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.isAccepted;  
        } else {
            return false;
        }    
    }    

    // user is admin
    function isAdmin() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.isAdmin;  
        } else {
            return false;
        }    
    }    

    // user is special
    function isSpecial() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.isSpecial;  
        } else {
            return false;
        }    
    }    

    // user lib
    function getLib() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.lib;  
        } else {
            return false;
        }    
    }    
    function getLibR() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.libr;  
        } else {
            return false;
        }    
    }    

    function getInitiales() {      
        if (user && decodeJwt(user.token)) {                  
            return jwt.user.initiales;  
        } else {
            return false;
        }    
    }    

    function getId() {      
        if (user && decodeJwt(user.token)) {
            return jwt.user.id;  
        } else {
            return 0;
        }    
    }    

    // inscription
    function signup(signupData) {

    }

    // logout
    function signout() {        
        clearLocalStorage();        
        setError('');     
        setUser(null);        
    }

    // autoLogin anonymous
    function signupAnonymous(callback) {
        if (!isLoading) {
            if (!isInit) {
                initFromLocalStorage();                
            } else {   
                if (!user) {
                    signin(anonymousLogin, anonymousPassword); 
                }    
            }    
        }    
    }

    // impersonated
    function setImpersonatedUserId(userId) {
        if (isAdmin()) {
            setForcedUserId(user)
        }
    }

    function getImpersonatedUserId() {
        return isAdmin()  ?  forcedUserId : null;
    }


    // tentative de connexion avec google
    /*
    function continueWithGoogle(credentialGoogle) {
        if (isLoading) return;
        setIsLoading(true);

        fetch(apiBaseUrl + "/token_g", {
            signal : AbortSignal.timeout(30000),
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
                "MadeBy64-Token" :  app.ctxToken || ""
            },
            body: JSON.stringify(credentialGoogle)
        }).then((response) => {
            // console.log(response);            
            setIsLoading(false);                 
            if (response.ok) {                
                return response.json();
            } else {                
                //setError('(' + response.status + ') ' + response.statusText);     
                return response.json();  // pour récup returnMsg
            }
        }).then( (data) => {            
            // console.log(data);
            if (data.returnCode===0) {                   
                if (decodeJwt(data.returnData.token)) {            
                    //console.log(jwt);
                    setUser(data.returnData);
                    setLocalStorage(data.returnData);
                } else {
                    setUser(null);                   
                    clearLocalStorage();
                    setError('JWT error - no token');         
                }    
            } else {
                setUser(null);            
                setLocalStorage(null);                    
                setError(data.returnMsg);     
                return
            }
        }).catch( (err) => {
            setIsLoading(false);                 
            setError('Identification impossible (' + err.message +')');     
        });
    }
    */

    return {
        apiBaseUrl
        ,user
        ,signin
        ,signup
        ,signout
        ,signupAnonymous
        ,refresh
        ,error
        ,isLoading
        ,isConfirmed
        ,isAccepted
        ,isAdmin
        ,isSpecial
        ,getLib
        ,getLibR
        ,getInitiales
        ,getId
        ,setImpersonatedUserId
        ,getImpersonatedUserId

        //,continueWithGoogle
    }
}