import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";
import { LoadingIcon } from "#components/Loading";
import {TextError , TextMessage} from '#components/TextInformation';

import { useApiPackages } from "#services/apiPackages";         

import ScrollButtonToTop from '#components/ScrollButtonToTop'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import useFunctions  from '#utils/functions';


export function Packages() {

    const {user} = useAuth();
    const api = useApiPackages();
  
    const {formatDate} = useFunctions();

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);        

    const [isLoaded, setIsLoaded] = useState({tab0: false, tab1: false, tab2: false } );
    const [data, setData] = useState({data0 : {}, data1:{}, data2:{}})

    //--------------------------------------------------------------        
    const columns = {
        // en cours
        cd0 : [  
        { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header'},
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120, type :'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.startDateValidity); 
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
            }
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120, type :'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.endDateValidity); 
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
            }
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 60, type : 'number', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            },
        },
        { field: 'upk_is_active', headerName: 'Actif', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},        
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}
        
      ],
      // en attente
      cd1 : [        
        { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header'},
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120, type : 'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.startDateValidity) ;
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
            }
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120, type :'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.endDateValidity);
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
            }
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120, type :'number', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            },
        },
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}        
      ],
      // historique
      cd2 : [
        { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header' },
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120, type : 'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.startDateValidity) || null;
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
            }
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120, type : 'date', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return new Date(params.row.upk_data.status.endDateValidity) || null;
            },
            valueFormatter: (params) => {
                return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.endDateValidity || '' );
            }
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120, type :'number', headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            }
        },
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' }        
      ]
    };       
  

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };  
  
    const getRowId = (data) => {
        return data.upk_id;
    }
  
    useEffect(() => {      
        if (user && !isLoaded["tab" + activeTab]) { 
          setIsWorking(true);
          api.getData(activeTab)
          .then ((json) => {          
            setIsWorking(false);
            let tempL = {...isLoaded};
            let tempD = {...data};
            tempL["tab" + activeTab]=true;
            if (json.returnCode===0) {     
              tempD["data" + activeTab] = json.returnData;   
              setErrorMessage("");
            } else {            
              tempD["data" + activeTab] = {};   
              setErrorMessage(json.returnMsg || 'Aucun abonnement');
          
            }  
            setData(tempD);       
            setIsLoaded(tempL);                 
          }); 
        }
        
    }, [user, activeTab, isLoaded]);

    const getData = (tabId) => {
        let key = "data" + tabId;
        return (data[key] && data[key].length>0) ? data[key] : null;
    }
    const getCol = (tabId) => {
        let key = "cd" + tabId;
        return (columns[key] && columns[key].length>0) ? columns[key] : null;
    }

    return (
        <Fragment>
            <TitlePage titlePage="Abonnements" />  

            {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
            {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

            <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">

                {/* les tabs  */ }
                <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="En cours" />
                    <Tab label="En attente" />                
                    <Tab label="Historique" />
                    </Tabs>
                </Box>

                {/* les datas */}
                {!getData(activeTab) && <Container align="center" sx={{ py : 5}}><CircularProgress /></Container>}            
                {getData(activeTab) && 
                    <DataGrid
                    loading = {isWorking}
                    getRowId={getRowId}
                    rows={getData(activeTab)}
                    columns={getCol(activeTab)}
                    density='compact'
                    disableColumnSelector={true}
                    initialState={{
                        pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                        },
                    }}
                    filterMode = 'client'
                    pageSizeOptions={[100]}
                    />
                }
            </Container>
            
            <ScrollButtonToTop />

        </Fragment>
    );    
};