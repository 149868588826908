import React, { Fragment, useState  } from "react";

import { useAuth } from "#utils/auth";

import { TitlePage } from "#components/TitlePage";
import {RequestReservation } from "#components/RequestReservation";


export function AddReservation() {

    const [showResult, setShowResult] = useState(false);

    return (
        <Fragment>
            { 
                showResult 
            ?  
                <TitlePage titlePage="Résultat de votre réservation" />
            :
                <TitlePage titlePage="Ajouter une réservation" />
            }  
            <RequestReservation showResult={showResult} setShowResult={setShowResult} />
        </Fragment>
    );    


}    