import React from "react";

import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

const dataOccupation = [
    { id: 1, lib: "Acupuncture"},
    { id: 2, lib: "Diététique"},
    { id: 3, lib: "Hypnose"},
    { id: 4, lib: "Infirmerie"},
    { id: 5, lib: "Médecine / Chirurgie"},
    { id: 6, lib: "Ostéopathie"},
    { id: 7, lib: "Obstétrie"},
    { id: 8, lib: "Psychologie"}
  ];
  
  const dataSpeciality = [
    { id: 1, lib: "Allergologie"},
    { id: 2, lib: "Angiologie"},
    { id: 3, lib: "Cardiologie"},
    { id: 4, lib: "Chirurgie digestive"},
    { id: 5, lib: "Chirurgie esthétique"},
    { id: 6, lib: "Chirurgie vasculaire"},
    { id: 7, lib: "Chirurgie du Rachis"},
    { id: 8, lib: "Médecine du sport"},
    { id: 9, lib: "Médecine esthétique"},
    { id: 10, lib: "Médecine générale"},
    { id: 11, lib: "Orthopédie"},
    { id: 12, lib: "Pneumologie"},
    { id: 13, lib: "Stomatologie"}
  ];  
  
export const SelectOccupation = (props) => {

    return (
        <Select {...props}>
        <MenuItem value=""><em>--</em></MenuItem>
        {
          dataOccupation.map((value, index) => (                
            value.lib && <MenuItem key={index} id={index} value={value.lib}>{value.lib}</MenuItem>                 
          ))
        }       
        </Select>     
   );
}    

export const SelectSpeciality = (props) => {
  
  return (
      <Select {...props}>
      <MenuItem value=""><em>--</em></MenuItem>
      {
        dataSpeciality.map((value, index) => (                
          value.lib && <MenuItem key={index} id={index} value={value.lib}>{value.lib}</MenuItem>                 
        ))
      }       
      </Select>     
 );

}      
