import { useState, useEffect, useCallback } from 'react'
import { useAuth } from '#utils/auth';

import { FaSpinner } from 'react-icons/fa';


//--------------------------------------------------------
export function useFetch(config) {
    const auth = useAuth();        

    const [ok, setOk] = useState(false);
    const [data, setData] = useState(null);

    const [message, setMessage] = useState(<FaSpinner className="loading-icon" />);

    const doFetch = useCallback( () => {
        if (!config.resource) {
            setMessage('No resource ...');                
            return;
        }    
        //console.log('Fetch ==>', auth.user);
        if (!auth.user) {                       
            if (!auth.error) {                
                auth.signupAnonymous();            
            }    
        } else {    

            let aSorters = config.sorters ||'';
            let aFilters= config.filters || '';

            fetch(auth.apiBaseUrl + config.resource + '?filters=' + JSON.stringify(aFilters) + '&sorters=' + JSON.stringify(aSorters), {
                signal : AbortSignal.timeout(30000),
                method : config.method || 'GET',
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
                },
                body  : config.body ? JSON.stringify(config.body) : undefined
            }).then((response) => {
                if (response.ok) {                
                    return response.json();
                } else {
                    setOk(false);
                    setMessage('erreur...');
                    return false;
                }
            }).then( (data) => {                        
                if (data) {
                    setOk(data.returnCode===0);
                    setData(data.returnData || null);
                    setMessage(data.returnMsg || '');                
                }    
            }).catch( (err) => {
                setOk(false);                 
                setMessage(err.message);     
            });
        }
    }, [auth, config]);

    useEffect(() => {        
        doFetch();
        if (auth.error) {
            setOk(false);
            setMessage(auth.error);                            
        }    
    }, [auth.user, auth.error]); 

    return {ok, data, message };
}