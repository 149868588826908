import React,  {Fragment, useState, useEffect }  from "react";

import {useSearchParams} from 'react-router-dom';

import {useApiMyPassword} from "#services/apiMyPassword";

import { TitlePage } from "#components/TitlePage";
import { TextPageCenter } from "#components/TextPageCenter";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { PasswordField } from "#components/PasswordField";
import {HtmlSpan} from "#components/HtmlSpan";
import { TextMessage, TextError } from "#components/TextInformation";

import Button from '@mui/material/Button';

export function RequestPassword(props) {

  const api = useApiMyPassword();
  const [queryParameters] = useSearchParams()

  const [isRequestOk, setIsRequestOk] = useState(false);
  const [isResetPasswordOk, setIsResetPasswordOk] = useState(false);

  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [errors, setErrors] = useState({});
  
  const [token, setToken] = useState(null);

  useEffect(() => {      
    setToken(queryParameters.get('token'));
    console.log(queryParameters.get('token'));
  }, []);
  
  
  const handleSubmit = (event) => {
      event.preventDefault();
      const dataForm = new FormData(event.currentTarget);
      
      const data = Object.fromEntries(dataForm.entries());
      let newErrors={};
    

      if(!!token) {
        if (!data.usr_password) newErrors.usr_password='Votre password est obligatoire';
      } else {  
        if (!data.usr_email) newErrors.usr_email='Votre email est obligatoire';
  //      if (!data.usr_idrpps) newErrors.usr_idrpps='Votre Id RPPS / Adelie est obligatoire';                
      }

      setErrors(newErrors);

      // si pas d'erreurs on update
      if (Object.entries(newErrors).length === 0 && newErrors.constructor === Object) {        
        //console.log(Object.fromEntries(dataForm.entries()));
        setErrorMessage('');

        if (!!token) {
          // update le password
          data.usr_request_token=token;
          api.updatePassword(data)
            .then ((json) => {                    
              //console.log(json);
              if (json.returnDataCount>0) {
                setIsResetPasswordOk(true);            
                setInfoMessage('Votre password est réinitialisé.');
              } else {
                setInfoMessage('');
                setErrorMessage(!!json.returnMsg ? json.returnMsg : 'Réinitialisation non réalisée');
                setIsResetPasswordOk(false);            
              }
            });

        } else {
          // create a request
          api.createRequest(data)
            .then ((json) => {                    
              //console.log(json);
              if (json.returnDataCount>0) {
                setIsRequestOk(true);        
                setInfoMessage('Votre demande est enregistrée.<br />Surveillez votre messagerie ... <br />Sans oublier de vérifier vos indésirables !');
              } else {
                setInfoMessage('');
                setErrorMessage(!!json.returnMsg ? json.returnMsg : 'Demande invalide');
                setIsRequestOk(false);            
              }
            });
        }
      } else {
        setErrorMessage('Certaines données sont incomplètes ou erronées !');
        setInfoMessage('');
        //console.log(errors);
      }
  };      

  return (
      <Fragment>
      <TitlePage titlePage="Réinit mot de passe" />  

      <Container sx={{ pt: 0, pb : 4 }} maxWidth="sm">

        {!token  ?
        <TextPageCenter>
        Indiquez votre email puis cliquez sur le bouton pour recevoir un message vous indiquant la procédure à suivre
        <br /><br />Attention, si votre email est incorrect, vous ne recevrez aucun message!        
        </TextPageCenter>
        :
        <TextPageCenter textAlign="center">
        Indiquez votre nouveau mot de passe<br /> puis cliquez sur le bouton"        
        </TextPageCenter>
        }  

        {infoMessage!=='' &&  <TextMessage><HtmlSpan text={infoMessage} /></TextMessage>}
        {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}



        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>      
          {!token 
          ? (isRequestOk ? "" :
            <>
            <TextField
              margin="normal"            
              required
              error={!!errors.usr_email}
              helperText={errors.usr_email} 
              fullWidth
              size="small"
              id="usr_email"
              label="Votre Email"
              name="usr_email"
              defaultValue=""
              autoComplete="email"
              type="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > 
            Réinitialisation du mot de passe
            </Button>
            </>
            )
          : 
            (isResetPasswordOk ? "" : <> 
            <PasswordField
              margin="normal"            
              required
              error={!!errors.usr_password}
              helperText={errors.usr_passwrd} 
              fullWidth
              size="small"
              id="usr_password"
              label="Votre nouveau mot de passe"
              name="usr_password"
              defaultValue={''}                    
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > 
            Réinitialisation du mot de passe
            </Button>
            </>
            )
          }  

          </Box>
      </Container>
      </Fragment>
    );
}