import React, { useState, useEffect } from "react";

import { useApiMyReservations } from "#services/apiMyReservations";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { green, blue, red } from '@mui/material/colors';

//import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BadgePackage from "#components/BadgePackage";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useFunctions  from '#utils/functions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#888888",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(
        ({ theme, expand }) => ({
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            })
        })
    );


export default function CardMyPackage(props) {

    const apiUrv = useApiMyReservations();

    const {isValidDate, countDaysLeft, formatDate} = useFunctions();

    const [isActive, setIsActive] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const [rangeValidity, setRangeValidity] = useState('-');    

    const [expanded, setExpanded] = useState(false);
    const [histoUrv, setHistoUrv] = useState(null);    

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const data = props.data;
    const upk_id = props.data.upk_id || 0;
    const upk_data = props.data.upk_data || {};

    //const upk_histo_usage = props.data?.upk_data?.usageHistory || [];
    const upk_histo_billing = props.data?.upk_data?.billingHistory || [];

    const nbDaysLeft = countDaysLeft(upk_data.status && upk_data.status.startDateValidity, upk_data.status && upk_data.status.endDateValidity );

    useEffect(() => {
        if (upk_data.status) {
            var ds = new Date(upk_data.status.startDateValidity);
            var de = new Date(upk_data.status.endDateValidity);
    //console.log(de,ds);        
            if (isValidDate(ds) && isValidDate(de)) {
                setRangeValidity("Valide du " + formatDate(ds) + " au " + de.toLocaleDateString("fr"));    
                if (de < new Date() ) {
                    setIsExpired(true);
                    setIsRunning(false);
                } else {
                    setIsExpired(false);
                    setIsRunning(true);
                }    
            } else {
                setRangeValidity('Plage de validité non définie');    
            }
        }
        
        setIsActive(data.upk_is_active);

        
    },[])

    useEffect(() => {
        if (expanded && !histoUrv)  {
            // chargement de l'histo reservation
            apiUrv.getHistoForUpk(upk_id)
            .then ((json) => {          
              if (json.returnCode===0) {        
                setHistoUrv(json.returnData);
              } else {            
                setHistoUrv(null);
              }  
            }); 
    
        }
    },[expanded])


    //const pri_data = props.data.upk_pri_data || {};
    
    return (
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <CardHeader           
                sx={{ backgroundColor : "#DEDEDE", py :1.52, minHeight:60}} 
                avatar={                    
                    <BadgePackage isRunning={isRunning} isActive={isActive} typePass={upk_data.purchased.priData.typePass}>
                        <Avatar sx={{ mt: -2, bgcolor: (isActive ? (isRunning ? green[400] : red[600])  : blue[300]), fontSize:14, width:50, height:50}} aria-label="recipe">
                        {data.upk_data.purchased.priData.nbPass}
                        </Avatar>
                    </BadgePackage>
                }                
                title={
                    <Typography gutterBottom component="h4" variant="h7" sx={{py:0,mb:0}}>{data.upk_data.purchased.priData.lib}</Typography>
                }
                subheader={
                    isActive 
                    ?
                    <Box sx={{ display: 'flex', flexDirection:'column', py : 0, my :0}}>
                        <Typography sx={{ fontSize: 11, mx:0, px : 0, color:"gray" }}>
                            {rangeValidity}
                        </Typography>

                        { isRunning && (
                            nbDaysLeft<130 
                                ?
                                <Typography gutterBottom  sx={{ fontSize: 13, mx:0, px:0, pt:0.5, pb:0, color : 'red', fontWeight :600 }}>                            
                                    Expire dans {nbDaysLeft} jours.
                                </Typography>
                                :
                                <Typography gutterBottom  sx={{ fontSize: 13, mx:0, px:0, pt:0.5, pb:0, color:'transparent' }}>                            
                                    -
                                </Typography>
                        )}

                        {isExpired && (
                            <Typography gutterBottom  sx={{ fontSize: 13, mx:0, px:0, pt:0.5, pb:0, color : 'red', fontWeight :600 }}>
                                Expiré.
                            </Typography>
                        )}

                    </Box>
                    :
                    <Box sx={{ display: 'flex', flexDirection:'column', py : 0, my :0}}>
                        <Typography sx={{ fontSize: 14, mt: 2, mb:1 , mx:0, px : 0, color:blue[500], fontWeight :600 }}>
                            {"En attente d'activation"}
                        </Typography>
                    </Box>
                }  
                action={
                    !isExpired &&
                        <Box sx={{ display: 'flex', flexDirection:'column'}}>                 
                            <Typography gutterBottom  sx={{ fontSize: 11, color: 'gray', textAlign:'center' }}>solde</Typography>
                            <Avatar sx={{ bgcolor: red[100], border : '1px solid red', color : 'black',  fontSize:14, width:35, height:35}} aria-label="recipe">
                                    {data.upk_data.status && data.upk_data.status.remainingPass}
                            </Avatar>
                        </Box>
                }
            />

            <CardContent  sx={{ my:0, pt:1, pb:0, px:2 }} >
                <Typography paragraph sx={{ fontSize: "1em", mx:0, my :0, py:0, px : 0 }}>
                        {data.upk_data.purchased.priData.description}
                </Typography>

                { // si options
                data.upk_data.purchased.optionsSelected && data.upk_data.purchased.optionsSelected.length>0 && data.upk_data.purchased.priData.optionsAllowed && data.upk_data.purchased.priData.optionsAllowed.length>0
                && 
                    <>
                    <Typography paragraph sx={{ fontSize: "0.9em", mx:0, px : 0, pt:1, my : 0 }}>
                        Option(s) sélectionnée(s) :
                    </Typography>                    
                    <ul style={{ marginTop: 0.5, marginBottom: 1, fontSize: "0.9em" }}>
                    {    
                        data.upk_data.purchased.optionsSelected.map((value, index) => (
                            <li key={index}>{data.upk_data.purchased.priData.optionsAllowedLib[value]} </li>
                        ))
                    }
                    </ul>                    
                    </>
                }
            </CardContent>                    
            <CardActions sx={{ my:0, py:0, pl: 2, pr :2 , display:'flex', flexDirection:'row'}} disableSpacing>
                {/*
                <Typography sx={{ fontSize: "0.8em", py:0, my : 0, backgroundColor:"#DEDEDE"}}>
                    {expanded ? "Masquer historique" : "Voir historique"} 
                </Typography>                    
                */}
                <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                >                    
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ backgroundColor: '#EFFFFF'}}>

                    <Typography paragraph sx={{fontWeight:400, mb :0.5 }}> Historique des réservations :</Typography>
                    <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }} size="small" aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Horaire</StyledTableCell>
                                <StyledTableCell align="right">Nb Pass</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {histoUrv && histoUrv.map((row, index) => (
                                <TableRow
                                    key={row.urv_date + index} 
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">{new Date(row.urv_date).toLocaleDateString("fr")}</TableCell>
                                <TableCell>{row.urv_tis_lib}</TableCell>
                                <TableCell align="right">{row.urv_nb_pass_cost}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                    <Typography paragraph sx={{mt:2, mb :0.5}}>Historique facturation :</Typography>
                    <TableContainer component={Paper}>
                    <Table sx={{ width: "100%" }} size="small" aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell align="right">Montant</StyledTableCell>
                                <StyledTableCell>Statut</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {upk_histo_billing && upk_histo_billing.map((row, index) => (
                                <TableRow
                                    key={row.day + index} 
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">{new Date(row.day).toLocaleDateString("fr") }</TableCell>
                                <TableCell align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.amount)}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                </CardContent>
            </Collapse>
        </Card>

    );
}