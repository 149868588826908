import {useEffect} from "react"; 

export default function useConfig() {
    const isLocal = window.location.href.indexOf('localhost:3000')!==-1 || window.location.href.indexOf('.l-madeby64.net')!==-1;
    const isRecette = window.location.href.indexOf('.madeby64.fr')!==-1;
    const isProd = window.location.href.indexOf('medipolecambrai')!==-1;

    const appVersion = window.APP_VERSION;

    var apiBaseUrl="";
    if (isLocal) {
        apiBaseUrl = "http://res-loc.l-madeby64.net/ws/0x001_Medi/";
    } else if (isRecette) {
        apiBaseUrl = "https://res-loc-sandbox.madeby64.fr/ws/0x001_Medi/"; 
    } else if (isProd) {
        apiBaseUrl = "https://res-loc.madeby64.fr/ws/0x001_Medi/";    
    }

    let dataConfig = {

        apiBaseUrl  : apiBaseUrl,
        
        app : {
            version : appVersion,
            code : "medipole",
            //ctxId : 1
            ctxToken : "db907070-3c1d-11ee-9078-8c85908be222"
        },    

        siteUrl : 'www.medipolecambrai.fr',
        siteName : 'Médipôle Cambrai',
        siteEmailContact : 'contact@medipolecambrai.fr',
        sitePhoneContact : null,  //'06 63 95 79 71',
        sitePhoneContactPro : '06 63 95 79 71',

        siteAddress : ['3 rue du premier de ligne','59400 CAMBRAI'],
        baseLineLogo : "Centre de consultations",

        anonymousLogin : 'anonymous@anonymous.medipole.xyz',
        anonymousPassword : 'anonymous',

        mustReload : false,

        defaultValues : {
            nbDaysCanDeleteUrv : 0    // nombre jours autorisés pour l'annulation. Attention mini 1 jour pour l'instant
        }
    }    

    useEffect(() => {    
        var ver = localStorage.getItem(dataConfig.app.code +'_version');    
        // on teste l'existance pour eviter un reload la première fois !
        //console.log('on teste la version', ver,"=",appVersion);
        if (!ver) {
            localStorage.setItem(dataConfig.app.code +'_version', appVersion );
        } else if (ver !== appVersion) {            
            dataConfig.mustReload=true; // window.location.reload();
        }
    }); 
    
    return dataConfig;
}