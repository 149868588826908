import React, { Fragment, useState, useEffect  } from "react";

import { useAuth } from '#utils/auth';
import { LoadingIcon } from "#components/Loading";
import { useApiMyDocuments } from "#services/apiMyDocuments";
import ScrollButtonToTop from '#components/ScrollButtonToTop';

import { TitlePage } from "#components/TitlePage";
import useFunctions  from '#utils/functions';

import { FaDownload } from 'react-icons/fa';

import Container from '@mui/material/Container';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#888888",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function MyDocuments() {

  const {user} = useAuth();
  const api = useApiMyDocuments();

  const {formatDate} = useFunctions();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({})
  const [message, setMessage] = useState(null)

  const [isLoadedG, setIsLoadedG] = useState(false);
  const [dataG, setDataG] = useState({})


  const [docToShow, setDocToShow] = useState(0);
  
  useEffect(() => {      
    if (user && docToShow>0) {
      api.getDocLink(docToShow)
        .then ((json) => {          
          if (json.returnCode===0) {    
            window.open(json.returnData.url,'_blank',);
          } else {
            console.log(json);
            setMessage('Aucun document n\'est actuellement disponible...');
          }  
        }); 
      setDocToShow(0);   
    }    
  }, [docToShow]);
  

  useEffect(() => {      
    (user && !isLoaded) && 
      // documents du user
      api.getData(0)
        .then ((json) => {          
          if (json.returnCode===0) {        
            setData(json.returnData);
            setIsLoaded(true);              
          } else {
            //console.log(json);
            setData({});
            setIsLoaded(false);
            setMessage('Aucun document n\'est actuellement disponible...');
          }  
        }); 

      // documents génraux
      api.getData(1)
        .then ((json) => {          
          if (json.returnCode===0) {        
            setDataG(json.returnData);
            setIsLoadedG(true);              
          } else {
            setDataG({});
            setIsLoadedG(false);
          }  
        }); 
        
  }, [isLoaded, user]);

  //console.log(isLoaded, data);

  return (
    <Fragment>
      <TitlePage titlePage="Mes documents" />

      {(!isLoaded || !isLoadedG) && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}      

      {isLoaded &&      
        <TableContainer component={Paper} sx={{ mt: 2, mb : 4 }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="simple table">
            <TableHead>
                <StyledTableRow>
                    <StyledTableCell>Document</StyledTableCell>
                    <StyledTableCell align="right">Mis en ligne le</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
              { data && data.length>0 
                ?  
                  data.map((row, index) => (
                    <TableRow
                          key={row.udc_id} 
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{ row.udc_data_libelle }</TableCell>
                      <TableCell align="right">{formatDate(row.udc_upload_time)}</TableCell>
                      <TableCell align="right"><FaDownload onClick={() => setDocToShow(row.udc_id)} /></TableCell>                      
                    </TableRow>                      
                  ))                  
                :
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" colSpan={2}>
                      { "Aucun document disponible" + (message ? ' (' + message + ')': '') }
                    </TableCell>
                  </TableRow>                      
              }
            </TableBody>
        </Table>
        </TableContainer>
      }    

      {(isLoadedG && dataG && dataG.length>0) 
        ?     
        <>
        <Container sx={{mt: 8 }} align="center">
          <Typography  sx={{fontSize:'1.3rem'}}>Documents génériques</Typography>
        </Container>        
        <TableContainer component={Paper} sx={{ mt: 1, mb : 4 }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="simple table">
            <TableHead>
                <StyledTableRow>
                    <StyledTableCell>Document</StyledTableCell>
                    <StyledTableCell align="right">Mis en ligne le</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>      
                {dataG.map((row, index) => (
                  <TableRow
                        key={row.udc_id} 
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{ row.udc_data_libelle }</TableCell>
                    <TableCell align="right">{formatDate(row.udc_upload_time)}</TableCell>
                    <TableCell align="right"><FaDownload onClick={() => setDocToShow(row.udc_id)} /></TableCell>                      
                  </TableRow>                      
                ))}
            </TableBody>
        </Table>
        </TableContainer>
        </> 
        : null
      }          
      <ScrollButtonToTop />
    </Fragment>
  );

};