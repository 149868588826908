import React, { Fragment } from "react";

import { TitlePage } from "#components/TitlePage";
import { ImageCenter } from "#components/ImageCenter";
import { TextPageCenter } from "#components/TextPageCenter";
import ScrollButtonToTop from '#components/ScrollButtonToTop';

//import NOP001 from "#assets/NOP001.jpg";
import NOP002 from "#assets/NOP002.jpg";
import NOP003 from "#assets/NOP003.jpg";
import NOP004 from "#assets/NOP004.jpg";
import NOP005 from "#assets/NOP005.jpg";
import NOP006 from "#assets/NOP006.jpg";
import NOP007 from "#assets/NOP007.jpg";
import NOP008 from "#assets/NOP008.jpg";
import NOP009 from "#assets/NOP009.jpg";
//import NOP010 from "#assets/NOP010.jpg";

//import Container from '@mui/material/Container';
//import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';

//import Grid from '@mui/material/Grid';
//import CardService from "#components/CardService";

import Link from '@mui/material/Link';
import useConfig from "#utils/config";

export const Services = () => {

  const cfg = useConfig();

  const linkPhone ="tel:" + cfg.sitePhoneContactPro;
  const linkEmail = "mailto:" + cfg.siteEmailContact;

  return (
    <Fragment>      

      <TitlePage titlePage="Nos offres aux professionnels" />
      <TextPageCenter>
      Médipôle Cambrai est un concept innovant de location de bureaux permettant l'exercice libéral médical et paramédical sans contraintes. Au Médipôle, les praticiens ne payent que lorsqu’ils louent un bureau de consultation !      
      </TextPageCenter>
      <TextPageCenter>
      Nos nombreuses installations (parking privé, bornes de recharge voiture, musique dans les zones d’attente et de circulation, wifi) contribuent à un environnement de travail de qualité. Les postes de secrétariat peuvent compléter les bureaux de consultation et vous permettent ainsi d’exercer votre activité dans votre propre environnement.      
      </TextPageCenter>                       
      <ImageCenter src={NOP002} alt="..." />    
      <TextPageCenter>
      La salle de détente entièrement équipée vous accueillera pour un café ou pour un déjeuner. Des casiers individuels et sécurisés sont également votre disposition.
      </TextPageCenter>
      <ImageCenter src={NOP003} alt="..." />
      <TextPageCenter>
      Au Médipôle, nous souhaitons mettre en avant et augmenter la visibilité des professionnels de santé qui nous font confiance. C’est pourquoi les praticiens sont affichés quotidiennement à l’entrée du bâtiment. Les praticiens exerçant au Médipôle sont également présentés dans la section “Nos praticiens” de notre site.
      </TextPageCenter>
      <ImageCenter src={NOP004} alt="..." />      
      <TextPageCenter>
      Le rooftop permet à nos praticiens de profiter de la vue panoramique le temps du déjeuner ou d’une pause détente.
      </TextPageCenter>  
      <ImageCenter src={NOP005} alt="..." />      

      <TextPageCenter>
        <Typography gutterBottom component="h2" variant="h5" sx={{pb :2}}>
          Les bureaux de consultation
        </Typography>
        Médipôle propose des salles et bureaux neufs, confortables et spacieux. Ils sont entièrement équipés et adaptés aux métiers des praticiens :
        <ul>
        <li>Bureau d’environ 25 m² équipé d’un bureau, une table d’examen et un plan de travail </li>
        <li>Salle d’examen d’environ 16 m² équipé d’une table d’examen et d’un plan de travail</li>
        <li>Salon privatif de 23 m² équipé d’un canapé, un fauteuil et d’un mobilier de salon</li>
        </ul>
        Ils sont proposés à la location toutes charges comprises :
        <ul>
        <li>Wifi et imprimante</li>
        <li>Accès personnalisé et sécurisé par badge</li>                
        <li>Ménage quotidien, vidéosurveillance et alarme</li>
        <li>Eau, électricité, chauffage et climatisation</li>
        </ul>        
      </TextPageCenter>  
      <ImageCenter src={NOP006} alt="..." />        

      <TextPageCenter>
        <Typography gutterBottom component="h2" variant="h5" sx={{pb :2}}>
          Réserver
        </Typography>
        Les professionnels de santé peuvent réserver un bureau de consultation de plusieurs manières :        
        <ul>
        {cfg.siteEmailContact && 
        <li>en nous contactant par mail à l’adresse suivante : <Link sx={{m:0, pt:0, pb:0 }} rel="noopener" href={linkEmail}>
            {cfg.siteEmailContact}
            </Link>
        </li>
        }
        {cfg.sitePhoneContactPro &&
        <li>en nous contactant par téléphone au : <Link sx={{m:0, pt:0, pb:0 }} rel="noopener" href={linkPhone}>
            {cfg.sitePhoneContactPro}
            </Link>
        </li>
        }
        <li>en utilisant notre interface de réservation depuis l’espace client de ce site. Cette interface est accessible aux clients Médipôle ayant créé un compte client (menu “Connexion”)  Elle permet aux praticiens de contracter un abonnement, réserver un bureau et suivre leur consommation.</li>
        </ul>
        Pour accompagner la récurrence de vos réservations, nous proposons des abonnements à des tarifs dégressifs selon la fréquence de votre venue au Médipôle. Nos abonnements permettent ainsi de louer un bureau à l’heure, à la demi-journée, ou à la journée. Chaque réservation étant décomptée de votre abonnement.
      </TextPageCenter>

      <TextPageCenter>
        <Typography gutterBottom component="h2" variant="h5" sx={{pb :2}}>
        Nos abonnements Premium
        </Typography>
        Les abonnements Premium peuvent être librement utilisés pendant un an (minimum de réservation : demi-journée) et vous permettent de réserver un grand bureau :
        <ul>
        <li>Pack 10 journées</li>
        <li>Pack 20 journées</li>
        <li>Pack 45 journées</li>
        <li>Pack 90 journées</li>
        </ul>
        Pour personnaliser et améliorer votre expérience au Médipôle, nous proposons des services complémentaires aux abonnements, valables pour chacune de vos réservations :
        <ul>
        <li>Réservation de borne de recharge pour voiture électrique (le paiement de la recharge est directement effectué à la borne)</li>
        <li>Location de terminaux (paiement et carte vitale)</li>
        </ul>
        <ImageCenter src={NOP007} alt="..." />        
        Ces services sont proposés à l’achat d’un abonnement depuis l’espace client.
        <br/><br/>
        <Link to="/RequestPricing" variant="body2">Pour connaître les prix des abonnements et des services complémentaires, cliquez ICI</Link>.

      </TextPageCenter>

      <TextPageCenter>
        <Typography gutterBottom component="h2" variant="h5" sx={{pb :2}}>
        Nos abonnements Standard
        </Typography>
        Nous proposons également des abonnements permettant de réserver librement un bureau Standard ou le petit salon privatif (côté Square). Les réservations peuvent être réalisées tout au long de l’année depuis l’espace client.
        <br/><br/>
        Ces abonnements, principalement destinés aux professions paramédicales, peuvent être utilisés librement du lundi au vendredi pendant un an selon plusieurs formules :
        <ul>
        <li>Pack 10 journées</li>
        <li>Pack 20 journées</li>
        <li>Pack 45 journées</li>
        <li>Pack 90 journées</li>
        </ul>
        La domiciliation de votre activité au Médipôle est totalement intégrée à tous nos abonnements IDEL. Quelque soit la formule choisie, ce service comprend la gestion quotidienne de votre courrier et la mise à disposition d’un casier sécurisé. En dehors des heures de réservation d’un bureau, votre abonnement permet à tout moment l’accès à la salle de pause, aux toilettes et au roof top.
        <ImageCenter src={NOP008} alt="..." />
        <Link to="/RequestPricing" variant="body2">Pour connaître les tarifs de nos abonnements Standard, cliquez Ici</Link>
      </TextPageCenter>

      <TextPageCenter>
        <Typography gutterBottom component="h2" variant="h5" sx={{pb :2}}>
        Nos abonnements IDE
        </Typography>
        Enfin, nous proposons des abonnements exclusivement destinés aux infirmiers(ères) IDE et permettant de réserver à l’heure une salle d’examen. Les réservations peuvent être réalisées tout au long de l’année depuis l’espace client.
        <br/><br/>
        Ces abonnements peuvent être librement utilisés 7/7 de 7h à 21h, aux dates et aux durées souhaitées selon deux formules :
        <ul>
        <li>Pack 100 heures / an</li>
        <li>Pack 150 heures / an</li>
        </ul>
        La domiciliation de votre activité au Médipôle est totalement intégrée à tous nos abonnements Standard. Quelque soit la formule choisie, ce service comprend la gestion quotidienne de votre courrier et la mise à disposition d’un casier sécurisé. En dehors des heures de réservation d’un bureau, votre abonnement permet à tout moment l’accès à la salle de pause, aux toilettes et au roof top.
        <ImageCenter src={NOP009} alt="..." />
        <Link to="/RequestPricing" variant="body2">Pour connaître les tarifs de nos abonnements IDE, cliquez Ici</Link>
      </TextPageCenter>

      <ScrollButtonToTop />
    </Fragment>
  )};
  