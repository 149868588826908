import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/index.css';

import { AuthProvider } from "./utils/auth";

// google  
// désactivé pour l'instant
//import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './components/App';

import * as swRegister from './sw-register';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
// google  
// <GoogleOAuthProvider clientId="1003046480605-3scl8094q81pmtl14ldbi3n1rmjd561u.apps.googleusercontent.com">  
    <AuthProvider>
      <App />    
    </AuthProvider>    
// google      
// </GoogleOAuthProvider>    
);

swRegister.register();