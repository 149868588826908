export default function useFonctions() {

    // test la validite d'une date
    function isValidDate(d) {
        //return d instanceof Date && !isNaN(d);
        return d instanceof Date && isFinite(d);
    }    
    
    // retourne le nb jours restants pou une plage 
    function countDaysLeft(startDate, endDate) {
        var today = new Date();
        var delta = 0;

        startDate = (typeof(startDate)==='string') ? new Date(startDate) : startDate;     
        endDate = (typeof(endDate)==='string') ? new Date(endDate) : endDate;     
        if (startDate && endDate) {
            if (today.getTime()>startDate.getTime()) {
                delta = (endDate.getTime() - today.getTime()) / (1000 * 3600 * 24);
            } else {
                delta = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
            }
        }
            
        return Math.ceil(delta ?? 0);
    }

    function formatDate(date, format) {
        //toLocaleDateString("fr")
        if (date && !isValidDate(date)) {
            date = new Date(date);
        } 
        if (isValidDate(date)) {
            return date.toLocaleDateString(format ?? "fr-FR");
        } else {
            return '-';
        }    
    }

    function formatDatetime(date, format) {
        //toLocaleDateString("fr")
        if (date && !isValidDate(date)) {
            date = new Date(date);
        } 
        if (isValidDate(date)) {
            return date.toLocaleDateString(format ?? "fr-FR") + ' ' + date.toLocaleTimeString(format ?? "fr-FR");
        } else {
            return '-';
        }    
    }

    function formatDateWithWeekday(date, format) {
        //toLocaleDateString("fr")
        if (date && !isValidDate(date)) {
            date = new Date(date);
        } 

        if (isValidDate(date)) {            
            return date.toLocaleDateString(format ?? "fr-FR", { weekday : "long", year :"numeric", month :"numeric", day: "numeric"});
        } else {
            return '-';
        }    
    }

    function formatEuro(value, nbFractionDigits) {
        if (isNaN(value)) {
            return '-';    
        } else {
            nbFractionDigits = isNaN(nbFractionDigits) ? 2 : nbFractionDigits;
            let euro = Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits : nbFractionDigits,
                maximumFractionDigits : nbFractionDigits
            });
            return euro.format(value);
        }    
    }
        
    return {        
        isValidDate
        , countDaysLeft
        , formatDate
        , formatDatetime
        , formatDateWithWeekday
        , formatEuro
    }    
}