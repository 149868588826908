import React from "react";
//import styled from 'styled-components';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { blue } from '@mui/material/colors';


export const TitlePage = (props) => {

    const text = props.titlePage
    const showAddButtonLeft = props.showAddButton==='left';
    const showAddButtonRight = props.showAddButton==='right';
    const tooltip = props.tooltipAddButton;
    const routeAddButton = props.routeAddButton;

    const AddButton = (()=>{
        return (
            < >
            <Tooltip title={tooltip} >
            <IconButton variant="contained" to={routeAddButton} sx={{ p: 0, m:0 }}>
                <AddCircleIcon sx={{ typography: { sm: 'h3', xs: 'h4' }, color: blue[500] }}/>
            </IconButton>    
            </Tooltip>    
            </>
        )
    });

    const NoButton = (()=>{
        return (
            <Icon sx={{ typography: { sm: 'h3', xs: 'h4' } }}/>
        )    
    });
    
    return (
        <Container sx={{ py: 0, display: 'flex', justifyContent: 'space-between'}} maxWidth="xm">
            { showAddButtonLeft ? <AddButton /> : <NoButton />}
            <Typography
                component="h1"                
                align="center"
                color="text.primary"                
                sx = {{
                    typography: { sm: 'h4', xs: 'h5' } 
                }}
                >
                {text}
            </Typography>
            { showAddButtonRight ? <AddButton /> : <NoButton /> }
        </Container>
    );
};
