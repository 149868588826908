import React from "react";

import Container from '@mui/material/Container';

export const ImageCenter = (props) => {

    const src = props.src;
    const alt = props.alt || '...';
    const width = props.width || '100%';
    const height = props.height || '';
    const maxWidth = props.maxWidth || 'sm';

    const imgStyle ={
        borderRadius: '1%',
    }

    return (
        <Container sx={{ px:"0.5em", py:"1em", textAlign:"center" }} maxWidth={maxWidth} >        
            <img src={src} alt={alt} width={width} height={height} style={imgStyle} />
        </Container>
    );
};
