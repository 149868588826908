import React, { Fragment } from "react";
import useConfig from "#utils/config";

import { TitlePage } from "#components/TitlePage";
import { ImageCenter } from "#components/ImageCenter";
import { TextPageCenter } from "#components/TextPageCenter";
import ScrollButtonToTop from '#components/ScrollButtonToTop';

import MED001 from "#assets/MED001.jpg";
import MED002 from "#assets/MED002.jpg";
import MED003 from "#assets/MED003.jpg";
import MED004 from "#assets/MED004.jpg";

export const Entreprise = () => {
  const {siteName} = useConfig();

  return (
    <Fragment>
      <TitlePage titlePage={siteName} />
      <ImageCenter src={MED001} alt="..." />      
      <TextPageCenter>
      Médipôle est un nouveau centre médical à Cambrai qui s’adresse aux professionnels de santé souhaitant exercer leur activité en toute liberté. Notre objectif est de permettre la venue et l’installation de praticiens médicaux et paramédicaux en leur proposant des bureaux de consultation à la location. Médipôle offre ainsi une solution d’accueil et de services nomades adaptée au rythme de chaque praticien avec des locations de courte, moyenne ou longue durée, avec ou sans engagement. Avec Médipôle, c’est tout le cambrésis qui peut bénéficier d’une offre de soins de proximité assurée par les professionnels de santé présents sur place.
      </TextPageCenter>
      <ImageCenter src={MED002} alt="..." />      
      <TextPageCenter>
      Médipôle propose un ensemble de 8 bureaux de consultation (6 premium et 2 standard) et 4 postes de secrétariat pour y accueillir patients et praticiens au coeur de la ville. Après avoir pris rendez-vous directement auprès du praticien, les patients peuvent se rendre au Médipôle pour une consultation dans des conditions optimales : parking privé gratuit, accueil clair et précis, salles de consultation spacieuses et confortables. Pour contacter les praticiens exerçant au Médipôle, retrouvez leurs coordonnées (Doctolib, secrétariat) dans la section “Nos praticiens”.
      </TextPageCenter>
      <ImageCenter src={MED003} alt="..." />      
      <TextPageCenter>
      <b>Biolam, laboratoire d’analyses de biologie médicale, a ouvert ses portes au Médipôle !</b> 
      <br /><br />Doté d'un personnel qualifié et compétent et d'équipements de pointe, BIOLAM s'engage à fournir des résultats précis et fiables dans les délais les plus courts possibles. 
Biolam vous accompagne également pour l'interprétation des résultats et les suivis médicaux. Votre nouveau laboratoire est à votre disposition pour répondre à toutes vos questions et vous accompagner dans votre parcours de santé.
Avec ou sans rendez-vous, Biolam vous accueille au sein du Médipôle et vous permet de bénéficier de ses nouveaux locaux spacieux et modernes.
<br />Horaires d’ouverture : Du Lundi au Vendredi de 08h00 à 18h30 et du Samedi : 08h00 - 12h30
<br />Tel : 0375089944
<br />Fax : 0362276647
<br />Mail : laboratoiremedipolecambrai@groupebiolam.fr 
      </TextPageCenter>
      <ImageCenter src={MED004} alt="..." />      

      <ScrollButtonToTop />
    </Fragment>
  )};